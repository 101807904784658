<template lang="pug">
.container
  page-title(:title='`Clientes (${count})`')
    button.button.is-primary(@click='create')
      | Nuevo cliente
  .card
    .card-content
      b-loading(is-full-page :active='clientsLoading')
      div(v-if='!clientsLoading')
        .actions-container
          div.flex
            b-button(@click='editSelected' :disabled='!selected' type='is-primary' outlined)
              | Editar
            b-button.ml(@click='activeSelected' :disabled='!selected' type='is-primary' outlined)
              | {{ activateBtnLabel }}
          b-field.ml
            b-switch(v-model='showActiveClients' @input='getClientsByStatus' :true-value='clientStates.active' :false-value='clientStates.inactive')
              | {{ showActiveClients }}
        custom-table(
          class='clients-grid'
          id='clients'
          :column-defs='columnDefs'
          :row-data='clients'
          row-data-key='id'
          @row-selected='clientSelected'
          @link-clicked='clientClicked'
        )
    client-form-modal(v-model='clientFormModalActive' :client-selected='selected || {}' @saved='getClientsByStatus')
</template>

<script>
import { mapState, mapActions } from 'vuex'
import clientsService from '@/services/clients.service'
import toastService from '@/services/toast.service'
import ClientFormModal from '@/components/clients/ClientFormModal'
import CustomTable from '@/components/CustomTable'
import { clientStates, clientsColumnDefs, labelBtnByStates } from './constants'

export default {
  components: { ClientFormModal, CustomTable },
  data () {
    return {
      columnDefs: clientsColumnDefs,
      selected: null,
      clientFormModalActive: false,
      clientStates,
      showActiveClients: clientStates.active
    }
  },
  created () {
    this.getClientsByStatus()
  },
  computed: {
    ...mapState({
      clients: state => state.clients.list,
      clientsLoading: state => state.clients.loading
    }),
    activateBtnLabel () {
      return labelBtnByStates[this.showActiveClients] || ''
    },
    count () {
      return this.clients?.length
    }
  },
  methods: {
    ...mapActions({
      getClients: 'clients/getClients'
    }),
    getClientsByStatus () {
      const active = this.showActiveClients === clientStates.active
      this.getClients(active)
    },
    create () {
      this.selected = null
      this.clientFormModalActive = true
    },
    clientSelected (row) {
      this.selected = row.rowData ? { ...row.rowData } : null
    },
    clientClicked (row) {
      if (row?.rowData?.id) {
        this.$router.push({ name: 'client', params: { id: row?.rowData?.id } })
      }
    },
    editSelected () {
      this.clientFormModalActive = true
    },
    activeSelected () {
      this.active()
    },
    async active () {
      await toastService.confirmWarning(
        `¿Está seguro de ${this.activateBtnLabel} el cliente?`,
        this.activateBtnLabel,
        async () => {
          const newClientStatus = this.showActiveClients !== clientStates.active
          await clientsService.active(this.selected.id, newClientStatus)
          this.getClientsByStatus()
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.actions-container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.clients-grid {
  height: calc(100vh - 210px);
}
</style>
